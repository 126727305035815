import User from './user';
import Main from './components/site/main.component';
import $j from 'jquery';
import BosonComponent from './components/component';
import Vue from 'vue';

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

/**
 * The main site class. Instantiates the main vue component and holds site functions
 */
class Site {

	private static instance: Site = new Site();
	public debug: boolean = true;
	public params = new URLSearchParams(window.location.search);
	public pathParts: string[] = [];
	public lastApi: any = null;

	public main: Main;
	public page: BosonComponent;

	public user: User;

	constructor() { }

	public init() {
		const self = this;

		console.log('- site init');

		(window as any).$j = $j;

		// lets attach the main site component
		const main = new Main();
		self.main = main;
		main.mount('#main', true);
		main.init();

		// create click handler to intercept links for SPA routing
		document.addEventListener('click', self.navigationHandler);

		self.route();
	}

	public static Get(): Site {
		return Site.instance;
	}

	/**
	 * Directs the app to a new page. Called directly or from intercepted link clicks
	 * @param path - The route to load. If none, sent, check the url
	 */
	public route(path: string = null): void {
		const self = this;
		
		// did we have a specific path? if not, use current url (on load)
		if (!path) path = window.location.pathname;

		// if there's a preceding slash, let's remove it
		if (path.charAt(0) == '/') path = path.slice(1);
		if (!path) path = 'dashboard';
		
		// refresh params
		self.params = new URLSearchParams(window.location.search);
		self.pathParts = path.split('/');

		console.log('- app routing to:', self.pathParts[0], path, Array.from(self.params));

		// tell main to load the page
		self.main.setPage( self.pathParts[0] );
	}

	/**
	 * Handles click events and intercepts navigation
	 * @param e - event
	 */
	public navigationHandler(e: MouseEvent): boolean {
		let target: any = e.target;

		// find the actual <a> tag
		while (target && target.tagName !== 'A') target = target.parentNode; // if there's an <a> around an <img> for example

		if (target && target.matches("a:not([href*='://'])") && target.href) {
			// don't handle with control keys or default prevented
			const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = e;
			if (metaKey || altKey || ctrlKey || shiftKey || defaultPrevented) return

			// don't handle right clicks
			if (button !== undefined && button !== 0) return;

			// don't handle target="_blank"
			if (target && target.getAttribute) {
				const linkTarget = target.getAttribute('target')
				if (/\b_blank\b/i.test(linkTarget)) return
			}

			// don't handle same page links/anchors
			const url = new URL(target.href)
			const to = url.pathname

			if (window.location.pathname !== to && e.preventDefault) {
				e.preventDefault()
				window.history.pushState({}, document.title, to);
				Site.instance.route( to );
			}
		}
	}

	/**
	 * Calls the detect server api.
	 * @param path - the api path, ex. `user/login`
	 * @param data - key/value object to send as post data
	 * @param silent - whether or not to log stuff, if it happens a lot
	 * @return the response json
	 */
	public async API(path: string, data: any = {}, silent: boolean = false): Promise<any> {
		const self = this;
		if (!path) {
			console.log('ERROR: api call needs path');
			return null;
		}


		const r = await $j.post('/api/' + path, {data} as any, 'json');
		if (self.debug && !silent) console.log('%c[api]', 'color: #CCC;', path, r);
		if (self.debug && !silent) self.lastApi = r;

		return r;

	}

	/**
	 * Sleeps for desired amount of time. Used for testing long loads, not for production
	 * @param ms - milliseconds to sleep for
	 */
	public async sleep(ms: number): Promise<any> {
		return new Promise(r => setTimeout(r, ms));
	}

	/**
	 * Requests from detect whether or not we're logged into SourceAudio as a super admin
	 */
	public async auth(): Promise<User> {
		const self = this;
		const r = await self.API('sa/saAuth');

		if (r.content?.id) {
			self.user = r.content;
			return r.content;
		}
	}

}

export default Site.Get();