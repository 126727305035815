import BosonComponent from '../component';
import Site from '../../site';

class MonitorPage extends BosonComponent {

	public name: string = 'site/monitor';

	/**@var the shared data between the class and the vue component */
	public data = {
		loaded: false,
		id: null,
		monitor: null,
		scan: null,
		crowFiles: [],
		charts: {
			scan: {
				series: [],
				options: {
					annotations: {
						position: 'front',
						xaxis: []
					},
					chart: {
						type: 'line',
						animations: { enabled: false },
						toolbar: {
							show: false
						},
						zoom: {
							enabled: false
						}
					},
					//markers: { size: 0 },
					dataLabels: {enabled: false},
					tooltip: {enabled: false},
					stroke: {
						curve: 'straight',
						width: .1
					},
					xaxis: {
						type: 'numeric',
						min: 87.9,
						max: 108.1,
						tickAmount: 20,
						tickPlacement: 'on',
						decimalsInFloat: 1,
						axisTicks: {
							show: true
						},
						labels: {
							hideOverlappingLabels: true
						}
					},
					yaxis: {
						tickAmount: 5
					}
				}
			}
		}
	};

	/**
	 * build the component from this context
	 */
	public constructor() {
		super();
		this.initComponent();
	}

	/**
	 * Gets the monitor data
	 */
	public async init(): Promise<void> {
		const self = this;

		console.log('- monitor detail page init', Site.pathParts);

		self.data.id = parseInt( Site.pathParts[1] );

		self.getMonitorData();
	}

	/**
	 * Gets monitor data from the server and formats the calculated values for the template
	 */
	private async getMonitorData() {
		const self = this;

		const {monitor, scan, crowFiles} = await Site.API('monitor/getMonitorData', { id: self.data.id });
		
		self.data.monitor = monitor;
		self.data.crowFiles = crowFiles;
		if (scan) {
			self.data.scan = scan;
			self.data.charts.scan.series.push({ name: 'Scan data', data: scan.data });

			const stationPeak = self.data.monitor.station.radioFrequency.includes('M') ? self.data.monitor.station.radioFrequency.replace('M', '') : self.data.monitor.station.radioHD.split(' ')[0];

			// annotations
			let annotations = [];
			let stationPeakFound = false;
			for (const peak of scan.peaks) {
				if (peak[4] < 3) continue;
				let color = '#775DD0';
				if (peak[0] == stationPeak) {
					color = '#FF3333';
					stationPeakFound = true;
				}
				annotations.push({
					x: parseFloat( peak[0]),
					strokeDashArray: 0,
					borderColor: color,
					label: {
						borderColor: color,
						style: {
							color: "#fff",
							background: color
						},
						text: peak[0],
						//offsetY: ((parseFloat( peak[0] ) * 10) + 1) / 2 % 2 ? 0 : 20
					}
				});
			}

			// add currently assigned station if not already there
			if (!stationPeakFound) {
				let color = '#CCCCCC';
				annotations.push({
					x: parseFloat(stationPeak),
					strokeDashArray: 0,
					borderColor: color,
					label: {
						borderColor: color,
						style: {
							color: "#fff",
							background: color
						},
						text: stationPeak + ' (!)',
						//offsetY: ((parseFloat( peak[0] ) * 10) + 1) / 2 % 2 ? 0 : 20
					}
				});
			}



			self.data.charts.scan.options.annotations.xaxis = annotations;
		} // end if scan

		self.data.loaded = true;

	}
}

export default MonitorPage;