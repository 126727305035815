import Vue, { Component } from 'vue';
import Templates from '../templates';

/**
 * The base class of each site component. Children need to define name, set a data object with all the keys reserved,
 * and the constructor needs to call initComponent, since the base class can't do it in the constructor.
 */
class BosonComponent {

	/**@var the component / template name*/
	public name: string;

	/**@var the Vue instance for this component*/
	public vm: Vue;

	/**@var the shared data between the class and the vue component */
	public data: any = {};

	
	/**
	 * Initializes the Vue component based on the child classes' settings. Should be called in child constructor, to maintain context
	 */
	public initComponent() {
		const self = this;
		console.log('-', self.name, 'component init', Object.getPrototypeOf(self).constructor.name);
		let template: Component;
		try {
			template = Templates.get(self.name);
		} catch (e) {
			console.error(e);
			throw new Error('No such Vue file:  /templates/' + self.name + '.vue');
		}

		// find methods
		const methods = Reflect.ownKeys(Object.getPrototypeOf(self));
		const methodsToAttach = {};
		for (const m in methods) {
			//console.log('- class member:', m, methods[m], typeof self[methods[m]], self[methods[m]].constructor.name);
			methodsToAttach[methods[m]] = function (...args: any) { self[methods[m]](...args); };
		}


		// convert name
		const niceName = self.name.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index == 0 ? word.toLowerCase() : word.toUpperCase();
		}).replace(/[^a-zA-z]/g, '');

		// the (as any) is because for some reason it thinks it's read only
		(template as any).name = niceName + 'Component';
		(template as any).data = function () { return self.data };
		(template as any).methods = methodsToAttach;
		self.vm = new Vue({
			render: createElement => createElement(template),
			renderError(h, err) {
				return h('pre', { style: { color: 'red' } }, err.stack)
			}
		});
	}

	/**
	 * Spot to initialize internal components or settings
	 */
	public init() {

	}

	/**
	 * Mounts the component to a particular element.
	 * @param el - The element query, like css
	 */
	public mount(el: string, replace: boolean = false): void {
		const self = this;
		const container = document.createElement('div');
		const div = document.querySelector(el);
		if (!div) console.error('No div found for', el);

		if (!replace) div.appendChild(container);
		self.vm.$mount(replace ? div : container);

		// trigger this on next tick, so it's actually mounted
		setTimeout(function () {
			self.onMount();
		}, 1);
	}

	/**
	 * Run after mounting, in case any initialization requires the dom to be active
	 */
	public async onMount(): Promise<void> { }

	/**
	 * Run on destroy, in case there's any cleanup to be done
	 */
	public async onDestroy(): Promise<void> { }

	/**
	 * Destroys the component's Vue component, and theoritically lines itself up for garbage collection
	 */
	public async destroy(): Promise<void> {
		const self = this;
		await self.onDestroy();
		self.vm.$destroy();
		self.vm.$el.remove();
		self.vm = null;
	}
}

export default BosonComponent;